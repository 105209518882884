<template>
  <div>
    <router-view v-if="havePermisssion" style="padding: 8px" />
    <a-result v-else status="403" title="403" :sub-title="$t('抱歉, 您无权访问此页面')" style="margin-top: 36px" />
  </div>
</template>

<script>
export default {
  components: {},
  computed: {
    isManager() {
      return this.$store.state.user.isManager;
    },
    permissions() {
      return this.$store.state.user.permissions;
    },
    havePermisssion() {
      const permission = this.$route.meta.permission;
      return this.isManager || this.permissions.indexOf(permission) !== -1;
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped></style>
